/* COLORS */

/* TYPOGRAPHY */
@font-face {
  font-family: "Matter";
  src: url("./fonts/Matter-Regular.woff2") format("woff2"),
    url("./fonts/Matter-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Matter";
  src: url("./fonts/Matter-SemiBold.woff2") format("woff2"),
    url("./fonts/Matter-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

/* BASIC RESET */
*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
}

@media (min-width: 1600px) {
  html {
    font-size: 120%;
  }
}

@media (max-width: 1199.98px) {
  html {
    font-size: 75%;
  }
}

@media (max-width: 991.98px) {
  html {
    font-size: 50%;
  }
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ol,
ul {
  margin: 0;
  padding: 0;
  font-weight: normal;
}

ol,
ul {
  list-style: none;
}

img {
  max-width: 100%;
  height: auto;
}

#root {
  font-family: "Matter", system-ui, -apple-system, BlinkMacSystemFont,
    "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 400;
  line-height: 1.6;
  font-size: 1.2rem;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  width: 100vw;
  height: 100vh;

  display: flex;
  overscroll-behavior: none;
}

/* LOGO */
.logo {
  position: fixed;
  top: 0;
  left: 0;
  padding: 1.5rem;
  width: 100%;
  max-width: 15rem;
}

/* BACKGROUND MODIFIERS */
.background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* CHART */
.chart-container {
  width: 55%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
  perspective-origin: 50% 10%;

  perspective: 85rem;
  perspective-origin: 50% 10%;

  overflow: hidden;
}

.chart {
  transform: rotateY(30deg) translateY(-36vh);
  transform-style: preserve-3d;
  display: flex;
  animation: move 4.5s linear;
}

/* BARS */
.bar {
  transform-style: preserve-3d;
  height: 65vh;
  width: 5rem;
  text-align: center;
  transform: translateY(50%);
  margin-right: 2.5rem;
}

/* BAR PERCENTUAL */
.bar__caption {
  color: white;
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  transform: rotate3d(0, 1, 0, 0deg) translateZ(2.5rem) translateY(3.5rem);
  position: absolute;
  bottom: 0;
  z-index: 100;
}

.bar__percentual {
  color: rgba(255, 255, 255, 0.9);
  background-color: rgba(0, 0, 0, 0.5);
  padding: 0.25rem 0.5rem 0.05rem 0.5rem;
  border-radius: 0.5rem;
  font-size: 1.2rem;
  font-weight: bold;
  opacity: 1;
}

/* BAR NAME */
.bar__name {
  width: 100%;
  font-size: 2rem;
  font-weight: bold;
  text-shadow: 0 0 1.25rem rgba(0, 0, 0, 0.5);
  transition: all 0.75s ease;
}

/* BAR SINGLE */
.bar__group {
  transform-style: preserve-3d;
  position: absolute;
  top: 0;
  left: 50%;
  transform-origin: 0 calc(65vh - 2.5rem) 0;
  transform: scaleY(0.01);
  transition: transform 5s cubic-bezier(0.19, 1, 0.23, 1);
}

.bar__face {
  transform-style: preserve-3d;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -2.5rem;
  width: 5rem;
  height: 65vh;
  backface-visibility: hidden;
  background: currentColor;
  outline: 1px solid rgba(255, 255, 255, 0.25);
  transition: all 0.75s ease;
  transform: translate3d(0, 0, 0);
}

.bar__face--top {
  transform: rotate3d(1, 0, 0, 90deg) translateZ(2.5rem);
  filter: brightness(0.85);
  width: 5rem;
  height: 5rem;
}

.bar__face--left {
  transform: rotate3d(0, 1, 0, -90deg) translateZ(2.5rem);
  filter: brightness(0.65);
}

.bar__face--front {
  transform: rotate3d(0, 1, 0, 0deg) translateZ(2.5rem);
  filter: brightness(1);
}

.bar__face--right {
  transform: rotate3d(0, 1, 0, 90deg) translateZ(2.5rem);
  filter: brightness(0.65);
}

/* BAR WINNER */
.bar.winner .bar__face {
  filter: brightness(1.25);
}

.bar.winner .bar__name {
  filter: brightness(1.25);
}

/* BAR REFLECTION */
.bar__face--front:before,
.bar__face--left:before {
  content: "";
  position: absolute;
  top: 100%;
  left: 0;
  width: inherit;
  height: inherit;
  opacity: 0.25;
  background: inherit;
  filter: blur(0.25rem);
}

/* ANIMATIONS */
@keyframes move {
  from {
    transform: rotateY(0deg) translateY(-36vh);
  }
}

@keyframes growCaption {
  0% {
    transform: scaleY(100);
  }
}

@keyframes growBar {
  0% {
    transform: scaleY(0.01);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
}

@keyframes fadeCaption {
  0% {
    opacity: 0;
  }
  35% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
}

/* LEGEND */
.legend-container {
  width: 45%;
  height: 100%;
  padding: 2rem 3rem 2rem 0;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* QUESTION */
.question {
  background-color: rgba(255, 255, 255, 0.05);
  color: rgba(255, 255, 255, 0.9);
  border: 1px solid rgba(255, 255, 255, 0.15);
  border-radius: 0.5rem;
  margin-bottom: 4vh;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
}

.question h2:after {
  content: "?";
  font-weight: bold;
  top: 50%;
  right: 10%;
  position: absolute;
  font-size: 1.6rem;
  transform: translateY(-50%) scale(9);
  color: rgba(255, 255, 255, 0.1);
}

.question h2 {
  font-size: 1.6rem;
  line-height: 1.2;
  font-weight: bold;
  position: relative;
  overflow: hidden;
  padding: 1.25rem 1.5rem 1.4rem 1.65rem;
}

/* ANSWERS */
.answers {
  width: 100%;
}

.answer {
  background-color: rgba(255, 255, 255, 0.05);
  color: rgba(255, 255, 255, 0.8);
  padding: 1rem 1.5rem 1rem 1.5rem;
  border: 1px solid rgba(255, 255, 255, 0.15);
  border-radius: 0.5rem;
  margin-bottom: 1vh;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  box-shadow: 0 0 2.5rem rgba(0, 0, 0, 0.5);
  transition: all 0.75s ease;
}

.answer p {
  font-size: 1rem;
  line-height: 1.4;
}

.answer p.answer__name {
  font-size: 2rem;
  font-weight: bold;
  line-height: 1;
  padding-right: 1.5rem;
  padding-left: 0.4rem;
  transition: all 0.75s ease;
}

.answer:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 0.25rem;
  height: 100%;
}

/* ANSWERS VARIATIONS */
.answer--1:before {
  background-color: var(--color-1);
}
.answer--2:before {
  background-color: var(--color-2);
}
.answer--3:before {
  background-color: var(--color-3);
}
.answer--4:before {
  background-color: var(--color-4);
}
.answer--5:before {
  background-color: var(--color-5);
}
.answer--6:before {
  background-color: var(--color-6);
}
.answer--7:before {
  background-color: var(--color-7);
}
.answer--8:before {
  background-color: var(--color-8);
}
.answer--9:before {
  background-color: var(--color-9);
}
.answer--10:before {
  background-color: var(--color-10);
}

/* WINNER ANSWER */
.answer.winner {
  color: rgba(255, 255, 255, 0.9);
  filter: brightness(1.25);
}

/* COUNTDOWN */
.countdown {
  background-color: rgba(0, 0, 0, 0.05);
  color: rgba(255, 255, 255, 0.9);
  padding: 0.7rem 1.1rem 0.5rem 1.1rem;
  border: 1px solid rgba(255, 255, 255, 0.15);
  border-radius: 0.5rem;
  margin-bottom: 1vh;
  backdrop-filter: blur(15px);
  -webkit-backdrop-filter: blur(15px);
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 1.5rem;
  font-weight: bold;
  align-self: flex-start;
  position: fixed;
  top: 1.5rem;
  left: 50%;
  transform: translateX(-50%);
}

/* VIDEO WRAP */

.video-wrap {
  background-color: red;
  padding: 2rem;
  border-radius: 0.5rem;
}

/* CONTROLS */
.controls {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 9999;
  padding: 1rem;
}
